<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item @click="crearPermisos()"> Crear permisos </v-list-item>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="suspenderUsuario()">
          Suspender Usuario
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog max-width="50%" v-model="dialog">
      <v-card v-if="selectedComponent === 'CrearAsignacion'">
        <CrearAsignacion :item="item" @cerrarDialog="cerrarDialog" />
      </v-card>

      <!-- <v-card v-if="selectedComponent === 'CrearPermisos'">
        <CrearPermisos :item="item" @cerrarDialog="cerrarDialog" />
        {{ crearPermisos() }}
      </v-card> -->
      <v-card v-if="selectedComponent === 'EliminarAsignacion'">
        <EliminarAsignacion
          :item="item"
          @cerrarDialog="cerrarDialog"
          @usuarioModificado="usuarioModificado"
        />
      </v-card>
      <!-- <v-card v-if="selectedComponent === 'SuspenderUsuario'">
        <SuspenderUsuario :item="item" @cerrarDialog="cerrarDialog" />
      </v-card> -->
      <v-card v-if="selectedComponent === 'RestablecerContraseña'">
        <RestablecerContrasena :item="item" @cerrarDialog="cerrarDialog" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//import CrearAsignacion from "./CrearAsignacion.vue";
import { mapActions } from "vuex";
// import CrearPermisos from "./CrearPermisos.vue";
//import EliminarAsignacion from "./EliminarAsignacion.vue";
// import SuspenderUsuario from "./SuspenderUsuario.vue";
import RestablecerContrasena from "./RestablecerContrasena.vue";
import axios from "axios";
export default {
  props: ["item"],
  components: {
    //CrearAsignacion,
    // CrearPermisos,
    //EliminarAsignacion,
    // SuspenderUsuario,
    RestablecerContrasena,
  },
  data() {
    return {
      dialog: false,
      items: [
        /*
        {
          title: "Crear Asignacion",
          component: "CrearAsignacion",
        },
        */
        // {
        //   title: "Crear Permisos",
        //   component: "CrearPermisos",
        // },
        /*
        {
          title: "Eliminar Asignacion",
          component: "EliminarAsignacion",
        },
        */
        // {
        //   title: "Suspender Usuario",
        //   component: "SuspenderUsuario",
        // },
        {
          title: "Restablecer Contraseña",
          component: "RestablecerContraseña",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    usuarioModificado() {
      this.$emit("usuarioModificado");
    },
    async suspenderUsuario() {
      try {
        const response = await axios.put(
          `/usuarios/suspender_usuario?usuario_id=${this.item.id}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        // alert(JSON.stringify(response.data, null, 2));
        console.log(response);
        alert("Usuario suspendido.");
        location.reload();
      } catch (error) {
        if (error) {
          this.errores.push(error);
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
        this.serverError = true;
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    },
    async crearPermisos() {
      try {
        const response = await axios.post(
          `/usuarios/crear_permisos?usuario_id=${this.item.id}`,
          {},
          {
            headers: {
              accept: "application/json",
            },
          }
        );
        // const formattedData = JSON.stringify(response.data, null, 2);
        // alert(formattedData);
        if (response.data.mensajes.length != 0) {
          alert("El ususario no tiene asignaciones.");
        } else {
          alert("Permisos creados.");
          location.reload();
        }
      } catch (error) {
        if (error) {
          this.errores.push(error);
          alert(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
        this.serverError = true;
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    },
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },
    cerrarDialog(dialog) {
      this.dialog = dialog;
    },
  },
};
</script>
