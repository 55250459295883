<template>
  <v-container v-if="user" fill-height fluid pa-10>
    <v-row align="center" justify="center">
      Hola {{ this.currentUser.nombre }}, Bienvenido a la página de faltantes de
      Central Express S.A. de C.V.
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["user", "currentUser"]),
  },
};
</script>
