import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './axios'
import store from './store';
import * as VueGoogleMaps from 'vue2-google-maps';
// import GmapVue from 'gmap-vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCrww1bKVlSS49SdrvvlCLQmDgiNlzd644', // Reemplaza con tu API Key de Google Maps
    libraries: 'places', // Opciones adicionales de la API de Google Maps
  },
});

//Api key antigua  AIzaSyCLSjizQYBz_A0Ssp0oP1jnsxVyN4_A-0M

// Vue.use(GmapVue, {
//   load: {
//     key: "AIzaSyCLSjizQYBz_A0Ssp0oP1jnsxVyN4_A-0M",
//     installComponents: true,
//   },
// });


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
