<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center teal lighten-3 accent-4 white--text">
        Asignaciones <v-spacer></v-spacer>
        <v-text-field
          class="mr-10"
          v-model="search"
          append-icon="mdi-magnify"
          label="Búsqueda"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <v-btn @click="abrirDialog" class="ml-3"> Crear Asignacion </v-btn>
      </v-card-title>
      <!-- Tabla de usuarios -->
      <v-data-table
        :headers="headers"
        :items="itemsNombre"
        :search="search"
        :items-per-page="-1"
        :footer-props="{
          'items-per-page-text': 'Usuarios por página',
        }"
        :header-props="{
          'sort-by-text': 'Ordenar por',
        }"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.usuario }}</td>
            <td>{{ item.perfil }}</td>
            <td>{{ item.sucursal }}</td>
            <td>
              <v-btn icon @click="borrarAsignacion(item.id)">
                <v-icon color="#00BFA5">mdi-delete</v-icon></v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog max-width="50%" v-model="DialogoCrearAsignacion">
        <v-card>
          <v-card-title class="text-center"> Crear Asignacion </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-select
                outlined
                label="Selecciona un usuario"
                :items="usuarios"
                item-text="nombre"
                item-value="id"
                v-model="usuarioSeleccionado"
                required
              ></v-select>
              <v-select
                outlined
                label="Selecciona un perfil"
                :items="perfiles"
                item-text="nombre"
                item-value="id"
                v-model="perfilSeleccionado"
                required
                @change="updateLocal"
              ></v-select>
              <v-select
                outlined
                label="Selecciona una sucursal"
                :items="sucursales"
                v-model="sucursalSeleccionada"
                :disabled="!puedeSeleccionarSucursal"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="CreacionAsignacion"
              >Crear</v-btn
            >
            <v-btn color="primary" text @click="cerrarDialog">Cancelar</v-btn>
          </v-card-actions>
        </v-card>

        <v-card v-if="selectedComponent === 'EliminarAsignacion'">
          <EliminarAsignacion
            :item="item"
            @cerrarDialog="cerrarDialog"
            @usuarioModificado="usuarioModificado"
          />
        </v-card>

        <v-card v-if="selectedComponent === 'RestablecerContraseña'">
          <RestablecerContrasena :item="item" @cerrarDialog="cerrarDialog" />
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
//import CrearAsignacion from "@/components/CrearAsignacion.vue";
import { mapActions } from "vuex";
// import CrearPermisos from "./CrearPermisos.vue";
//import EliminarAsignacion from "@/components/EliminarAsignacion.vue";
// import SuspenderUsuario from "./SuspenderUsuario.vue";
//import RestablecerContrasena from "@/components/RestablecerContrasena.vue";
import axios from "axios";

export default {
  props: ["item"],
  components: {
    //CrearAsignacion,
    // CrearPermisos,
    //EliminarAsignacion,
    // SuspenderUsuario,
    //RestablecerContrasena,
  },
  data() {
    return {
      DialogoCrearAsignacion: false,
      valid: true,
      usuarioSeleccionado: null,
      perfilSeleccionado: null,
      sucursalSeleccionada: "",
      usuarios: [],
      perfiles: [],
      puedeSeleccionarSucursal: false,
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      selectedComponent: null,
      itemsNombre: [],
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Usuario",
          value: "usuario",
        },
        {
          text: "Perfil",
          value: "perfil",
        },
        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "Eliminar",
          value: "eliminar",
        },
      ],
    };
  },
  methods: {
    usuarioModificado() {
      this.$emit("usuarioModificado");
    },

    async getUsuarios() {
      try {
        const usuariosAsignaciones = await axios.get(
          "/usuarios/get_asignaciones"
        );
        this.itemsNombre = usuariosAsignaciones.data;
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },

    async getUsuarios2() {
      try {
        const response = await axios.get("/usuarios/get_usuarios");
        this.usuarios = response.data.usuarios;
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
      }
    },

    async getPerfiles() {
      try {
        const response = await axios.get("/usuarios/get_perfiles");
        this.perfiles = response.data.perfiles;
        //console.log("Perfiles:", response.data.perfiles);
      } catch (error) {
        console.error("Error al obtener perfiles:", error);
      }
    },

    updateLocal(nombrePerfil) {
      // Buscar el perfil seleccionado en la lista de perfiles
      const perfil = this.perfiles.find((p) => p.id === nombrePerfil);
      //console.log("Perfil Seleccionado:", perfil);

      if (perfil) {
        // Verificar si la propiedad 'local' es false y habilitar la selección de sucursal
        this.puedeSeleccionarSucursal = perfil.local === true;

        // Si `local` es true, limpiar la selección de sucursal
        if (!this.puedeSeleccionarSucursal) {
          this.sucursalSeleccionada = "";
        }
      } else {
        console.error("Perfil no encontrado");
      }
    },

    async CreacionAsignacion() {
      try {
        // Enviar el PUT para crear la asignación
        const response = await axios.post(
          `/usuarios/crear_asignacion?id_perfil=${this.perfilSeleccionado}&usuario_id=${this.usuarioSeleccionado}&sucursal=${this.sucursalSeleccionada}`
        );

        console.log("Asignación creada:", response.data.resultado);

        // Agregar el nuevo usuario a la tabla
        this.itemsNombre.push({
          id: this.usuarioSeleccionado,
          usuario: this.usuarios.find((u) => u.id === this.usuarioSeleccionado)
            .nombre,
          perfil: this.perfilSeleccionado,
          sucursal: this.sucursalSeleccionada,
        });

        // Limpiar selección y cerrar diálogo
        this.cerrarDialog();
      } catch (error) {
        console.error("Error al crear la asignación:", error);
      }
    },

    async borrarAsignacion(id) {
      const confirmacion = confirm(
        "¿Seguro que deseas eliminar esta asignación?"
      );
      if (!confirmacion) return; // Si el usuario cancela la acción

      try {
        // Hacer la solicitud DELETE a la API
        await axios.delete(`/usuarios/eliminar_asignacion?id_asignacion=${id}`);

        // Actualizar la lista de asignaciones después de la eliminación
        this.itemsNombre = this.itemsNombre.filter((item) => item.id !== id);
        alert("Asignación eliminada con éxito.");
      } catch (error) {
        console.error("Error al eliminar la asignación:", error);
        alert(
          "Error al eliminar la asignación. Por favor, inténtalo de nuevo."
        );
      }
    },
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    ...mapActions(["setPerfil"]),
    openPerfil(perfil) {
      this.setPerfil(perfil);
      this.$emit("openPerfil"); // Emit an event to the parent component
    },

    abrirDialog() {
      this.DialogoCrearAsignacion = true;
    },
    cerrarDialog() {
      this.DialogoCrearAsignacion = false;
    },
  },

  mounted() {
    this.getUsuarios();
    this.getPerfiles();
    this.getUsuarios2();
  },
};
</script>
