<template>
  <v-dialog
    fullscreen
    transition="dialog-bottom-transition"
    max-width="80%"
    v-model="dialog"
  >
    <v-card>
      <v-container>
        <v-card-title>
          Agregar prepedido
          <v-spacer></v-spacer>
          <v-btn
            class="close-btn"
            icon
            value="log in"
            color="blue darken-1"
            text
            @click="cerrar()"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form ref="form" @submit.prevent="crearPrepedido()">
              <v-row
                v-if="
                  (!id_vendedor,
                  !id_cliente,
                  !contado,
                  !prioridad,
                  !formEnabled)
                "
              >
                <!--
                <v-col cols="12" md="8" lg="8">
                  <v-alert
                    color="warning"
                    outlined
                    prominent
                    :value="true"
                    icon="mdi-alert-outline"
                  >
                    Debes crear prepedido para despues agregar productos.
                  </v-alert>
                </v-col>
                -->
              </v-row>
              <v-row>
                <v-col cols="12" md="8" lg="8">
                  <v-alert
                    color="error"
                    outlined
                    prominent
                    :value="true"
                    icon="mdi-alert-outline"
                  >
                    DEBES DE LLENAR LA INFORMACIÓN DE ABAJO, PARA EMPEZAR A
                    AGREGAR PRODUCTOS.
                  </v-alert>
                </v-col>
              </v-row>

              <v-row v-if="!id_vendedor">
                <v-col cols="12" md="8" lg="8">
                  <v-alert
                    color="warning"
                    outlined
                    prominent
                    :value="true"
                    icon="mdi-alert-outline"
                  >
                    Debes seleccionar un vendedor y un cliente para poder crear
                    el prepedido.
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <v-autocomplete
                    v-model="clienteSelected"
                    :items="clientes"
                    :disabled="!id_vendedor"
                    item-text="Description"
                    :return-object="true"
                    outlined
                    clearable
                    label="Código de cliente"
                    append-icon="mdi-barcode"
                    no-data-text="No hay clientes para este usuario"
                    placeholder="Busca el cliente"
                    required
                  >
                    <template #item="{ item }">
                      <div>
                        <v-list-item-title>{{ item.codigo }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.rfc
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{
                          item.razon_social
                        }}</v-list-item-subtitle>
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="VerLocalizaciones"
                    :items="localizaciones"
                    :disabled="!clienteSelected"
                    item-text="descripcion"
                    :return-object="true"
                    outlined
                    clearable
                    label="Localizaciones"
                    append-icon="mdi-map-marker"
                    no-data-text="No hay localizaciones para este cliente"
                    placeholder="Busca una localización"
                    required
                  >
                    <template #item="{ item }">
                      <div>
                        <v-list-item-title>{{
                          item.descripcion
                        }}</v-list-item-title>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <v-select
                    outlined
                    v-model="id_vendedor"
                    :items="userIsAdmin ? getFilteredUsuarios : usuarios"
                    :item-text="(user) => user.nombre"
                    :item-value="(user) => user.id"
                    :filterable="true"
                    :label="'Vendedor'"
                    :disabled="!userIsAdmin"
                    @change="cambioDeVendedor"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4" lg="4">
                  <v-select
                    outlined
                    v-model="contado"
                    :items="opcionesContado"
                    :label="'Contado'"
                  >
                  </v-select>
                  <v-select
                    outlined
                    v-model="prioridad"
                    :items="opcionesContado"
                    :label="'Prioridad'"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="8" lg="8">
                  <v-textarea
                    outlined
                    label="Observaciones"
                    v-model="observaciones"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn
                :disabled="!esFormularioValido"
                type="submit"
                color="teal lighten-2"
                text
                @click="habilitarCampos"
                >Crear prepedido</v-btn
              >
            </form>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-on="on" v-bind="attrs">Agregar prepedido</v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
//import ModificarPrepedido from "./ModificarPrepedido.vue";

export default {
  props: ["entries", "entriesClientes", "usuarios"],
  data() {
    return {
      formEnabled: false,
      headers: [
        {
          text: "Producto",
          value: "producto",
          sortable: false,
        },
        {
          text: "Nombre",
          value: "nombre",
          sortable: false,
        },
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
        },
        {
          text: "En existencia",
          value: "existencia",
          sortable: false,
        },
        {
          text: "Precio",
          value: "precio",
          sortable: false,
        },
        {
          text: "Subtotal",
          value: "subtotal",
          sortable: false,
        },
        {
          text: "Eliminar",
          value: "eliminar",
          sortable: false,
        },
      ],
      opcionesContado: [
        {
          text: "Sí",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      contado: true,
      prioridad: false,
      dialog: false,
      productosTemp: [],
      prepedidosTemp: [],
      clientesSucursalVendedor: [],
      sucursales: [],
      codigo_producto: "",
      clienteSelected: null,
      cantidad_producto: "",
      id_vendedor: "",
      id_cliente: "",
      observaciones: "",
      sucursal: "",
      descriptionLimit: 60,
      dataProducto: [],
      llegadaProducto: [],
      isLoading: false,
      clientesUsuario: [],
      userIsAdmin: false,
      // datosLlegada: [],
      idPrepedido: [],
      //nueva implementacion de mostrar localizaciones del cliente
      VerLocalizaciones: null,
      localizaciones: [],
    };
  },
  methods: {
    habilitarCampos() {
      this.formEnabled = true;
    },
    cambioDeVendedor() {
      this.productosTemp = [];
      // console.log(this.entriesClientes);
      // const usuarioSelected = this.usuarios.find(
      //   (usuario) => usuario.id === this.id_vendedor
      // );

      // console.log(usuarioSelected);

      // Utilizar el método filter para obtener un nuevo array con clientes cuya sucursal sea la del vendedor
      // this.clientesSucursalVendedor = this.entriesClientes.filter(
      //   (cliente) => cliente.sucursal === usuarioSelected.sucursal
      // );

      // console.log(this.clientesSucursalVendedor);

      // Obtener los clientes del usuario
      this.getClientesDelUsuario(this.id_vendedor);
    },
    async loadObjects() {
      this.isLoading = true;
      try {
        //const idVendedor = this.userIsAdmin ? null : parseInt(this.id_vendedor);
        /*const url = this.userIsAdmin
          ? `/pedidos/get_pre_pedidos` // Cambia esta URL para obtener todos los prepedidos si el usuario es admin
          : `/pedidos/get_pre_pedidos?id_vendedor=${idVendedor}`; // Obtiene solo los prepedidos del vendedor si no es admin*/

        const response = await axios.get(
          `/pedidos/get_pre_pedidos?id_vendedor=${this.id_vendedor}`
        );
        console.log("Datos recibidos:", response.data);
        this.prepedidosTemp = response.data || [];
        console.log("Prepedidos cargados:", this.prepedidosTemp);
      } catch (error) {
        console.error("Error al cargar los prepedidos", error);
      } finally {
        this.isLoading = false;
      }
    },
    validarEntero() {
      this.cantidad_producto = this.cantidad_producto.replace(/\D/g, "");
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
    async crearPrepedido() {
      if (!this.esFormularioValido) {
        alert("Por favor, completa todos los campos obligatorios.");
        return;
      }

      this.isLoading = true;

      try {
        const datosPrepedido = {
          id_cliente: this.clienteSelected.id,
          id_vendedor: this.id_vendedor,
          observaciones: this.observaciones,
          contado: this.contado,
          prioridad: this.prioridad,
          localizacion: JSON.stringify(this.VerLocalizaciones), //this.VerLocalizaciones,
        };

        const response = await axios.post(
          "/pedidos/crear_pre_pedido",
          datosPrepedido
        );
        console.log("respuesta del servidor:", response.data);
        const idGenerado = response.data.id_objeto
          ? response.data.id_objeto
          : null;

        if (response.data && response.data.id_objeto) {
          const nuevoPrepedido = {
            id: idGenerado,
            id_cliente: datosPrepedido.id_cliente,
            id_vendedor: datosPrepedido.id_vendedor,
            observaciones: datosPrepedido.observaciones,
            contado: datosPrepedido.contado,
            prioridad: datosPrepedido.prioridad,
            localizacion: datosPrepedido.localizacion,
            //total: this.calcularTotal(),
          };
          //this.formEnabled = false;
          //this.dialog = false;
          this.$emit("solicitarPrepedidos");
          await this.loadObjects(nuevoPrepedido);
          this.$root.$emit("prepedidoCreado", nuevoPrepedido.id);
          console.log("Prepedido creado del evento:", nuevoPrepedido.id);
          this.dialog = false;
          alert("Prepedido creado con éxito. Ya puedes agregar productos!");
          this.id_cliente = "";
          this.observaciones = "";
          this.prioridad = false;
          this.contado = true;
          this.clienteSelected = null;
        } else {
          alert("ID del prepedido no recibido.");
        }
        //this.idPrepedido = response.data.id_objeto;

        //this.prepedidoCreado = true;
      } catch (error) {
        console.error("Error al crear prepedido:", error);
        alert("Ocurrió un error al crear el prepedido.");
      } finally {
        this.isLoading = false;
      }
    },
    async getClientesDelUsuario(idUsuario) {
      if (!idUsuario) {
        console.error("ID de usuario no definido");
        return;
      }

      try {
        const response = await axios.get(
          `/clientes/get_mis_clientes?vendedor_id=${idUsuario}`
        );
        this.clientesUsuario = response.data;
      } catch (error) {
        console.error("Error en la solicitud GET", error);
        if (error.response) {
          console.error("Respuesta del servidor:", error.response.data);
          console.error("Código de estado:", error.response.status);
        } else {
          console.error("Error de red o configuración:", error.message);
        }
      }
    },
    getUsuarioAdmin() {
      const permisos = this.currentUser.permisos.split(" ");

      // Definir los sufijos buscados
      const sufijosBuscados = [":ADMINISTRADOR"];

      // Comprobar si alguna parte termina con alguno de los sufijos buscados
      const algunaParteTerminaConSufijo = permisos.some((parte) =>
        sufijosBuscados.some((sufijo) => parte.endsWith(sufijo))
      );

      // algunaParteTerminaConSufijo será true si alguna parte termina con "_existencia", de lo contrario, será false
      this.userIsAdmin = algunaParteTerminaConSufijo;

      if (!this.userIsAdmin) {
        this.id_vendedor = this.currentUser.id;
        this.getClientesDelUsuario(this.id_vendedor);
      }
    },
    async obtenerLocalizaciones(clienteId) {
      try {
        // Realiza la solicitud GET para obtener las localizaciones
        const response = await axios.get(
          `/clientes/get_localizaciones?id_cliente=${clienteId}`
        );
        this.localizaciones = response.data || [];

        if (this.localizaciones.length === 0) {
          // Si no hay localizaciones, limpia VerLocalizaciones
          this.VerLocalizaciones = null;
        } else {
          // Busca la localización predeterminada y asigna el objeto completo
          const defaultLocation = this.localizaciones.find(
            (loc) => loc.default === true
          );
          this.VerLocalizaciones = defaultLocation || null;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          alert(
            "No se encontraron localizaciones para este cliente, No puedes crear el prepedido sin localizacion."
          );
        }
        console.error("Error al obtener localizaciones:", error);
        // En caso de error, limpia localizaciones y VerLocalizaciones
        this.localizaciones = [];
        this.VerLocalizaciones = null;
      }
    },
  },
  computed: {
    //nueva integracion
    esFormularioValido() {
      return (
        this.clienteSelected &&
        this.id_vendedor &&
        this.contado !== null &&
        this.prioridad !== null
      );
    },

    ...mapGetters(["currentUser"]),
    productos() {
      return this.entries.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        const nombreDescription =
          entry.nombre.length > this.descriptionLimit
            ? entry.nombre.slice(0, this.descriptionLimit) + "..."
            : entry.nombre;
        return Object.assign({}, entry, {
          Description: `${codigoDescription} - ${nombreDescription}`,
        });
      });
    },
    clientes() {
      console.log(this.clientesUsuario);
      return this.clientesUsuario.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        const nombreDescription =
          entry.razon_social.length > this.descriptionLimit
            ? entry.razon_social.slice(0, this.descriptionLimit) + "..."
            : entry.razon_social;
        return Object.assign({}, entry, {
          Description: `${codigoDescription} - ${nombreDescription}`,
        });
      });
    },
    getFilteredUsuarios() {
      return this.usuarios.filter((user) => {
        return user.nombre_adminpaq !== null;
      });
    },
  },
  watch: {
    clienteSelected(newCliente) {
      if (newCliente) {
        this.obtenerLocalizaciones(newCliente.id);
      } else {
        this.localizaciones = [];
        this.VerLocalizaciones = null;
      }
    },
  },
  mounted() {
    // Cargar objetos desde el almacenamiento local al iniciar el componente
    console.log(this.currentUser);
    this.getUsuarioAdmin();
    //this.loadObjects();
  },
  created() {},
  components: {},
};
</script>

<style>
.close-btn {
  float: right;
}

.texto-total {
  text-align: right;
}
</style>
