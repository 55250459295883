<template>
  <v-card tile>
    <!-- <v-card-title class="ml-4">Semana {{ semana }}</v-card-title> -->
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <h2 class="ml-4 mt-3">
            Semana {{ semana }} - Vendedor: {{ vendedorNombre }}
          </h2>
          <v-subheader>{{ rangoSemana }}</v-subheader>

          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Clientes visitados:
                  {{ datosClientes.clientesVisitados }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Clientes no visitados:
                  {{ datosClientes.clientesNoVisitados }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Clientes sin localización:
                  {{ datosClientes.clientesSinLocalizacion }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Total de clientes:
                  {{ datosClientes.total }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <!-- <v-container style="width: 250px; height: 250px">
            <DonutComponent :data="chartData" :options="chartOptions" />
          </v-container> -->
          <DoughnutComponent :datos="datosClientes" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn class="ml-6 mr-3 mb-3" @click="dialogClientes = !dialogClientes">
        Clientes</v-btn
      >
      <v-btn
        class="mb-3"
        rounded
        color="success"
        @click="mostrarMapa = !mostrarMapa"
      >
        <v-icon left>{{
          mostrarMapa ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
        Ver mapa
      </v-btn>
    </v-card-actions>

    <!-- dialog con la info de los clientes no vistados  -->
    <v-dialog v-model="dialogClientes" max-width="550px">
      <v-card>
        <v-card-title>
          Datos de clientes
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogClientes = !dialogClientes">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list subheader class="mb-2" color="#C8E6C9">
            <v-subheader>Clientes visitados</v-subheader>

            <v-list-item
              v-for="cliente in arrayClientesVisitados"
              :key="cliente.index"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="cliente.razon_social"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list subheader color="#FFE0B2">
            <v-subheader>Clientes no visitados</v-subheader>

            <v-list-item
              v-for="cliente in arrayClientesNoVisitados"
              :key="cliente.index"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="cliente.razon_social"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="mb-2"></v-divider>

          <v-list subheader color="#FFCDD2">
            <v-subheader>Clientes sin localización</v-subheader>

            <v-list-item
              v-for="cliente in arrayClientesSinLocalizacion"
              :key="cliente.index"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="cliente.razon_social"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Area de tabla de info de visitas y mapa -->
    <v-expand-transition>
      <div v-show="mostrarMapa">
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <v-data-table
              :items="visitas"
              :headers="headers"
              :single-select="true"
              v-model="selected"
              show-select
              item-key="index"
              :footer-props="{ 'items-per-page-text': 'Visitas por página' }"
              no-data-text="No hay visitas programadas esta semana"
              @click:row="onRowClick"
            >
              <template v-slot:[`item.duracion`]="{ item }">
                <!-- v-html para poder insertar un salto de linea dentro de la columna de la tabla -->
                <span v-html="calcularDuracion(item.inicio, item.fin)"></span>
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="visitas.length !== 0">
            <v-col>
              <v-btn plain color="primary" @click="visitaAnterior">
                Anterior
              </v-btn>

              <v-btn plain color="primary" @click="visitaSiguiente">
                Siguiente
              </v-btn>
            </v-col>

            <gmap-map
              :center="center"
              :zoom="zoom"
              class="mx-2 my-2"
              style="width: 640px; height: 360px"
            >
              <gmap-marker
                :position="markerVisita"
                icon="http://maps.gstatic.com/mapfiles/ms2/micons/man.png"
                title="Visita"
              ></gmap-marker>
              <gmap-marker
                :position="markerCliente"
                icon="http://maps.gstatic.com/mapfiles/ms2/micons/rangerstation.png"
                title="Cliente"
              ></gmap-marker>
            </gmap-map>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <div style="flex: 1 1 auto"></div>
  </v-card>
</template>

<script>
import DoughnutComponent from "./DoughnutComponent.vue";
import { format, parseISO } from "date-fns";
import { es } from "date-fns/locale"; // Importar el idioma español
export default {
  props: ["items", "semana", "rangoSemana", "vendedorNombre"],
  data() {
    return {
      loadingBtn: false,
      mostrarMapa: false,
      dialogClientes: false,
      center: { lat: 32.62472003503429, lng: -115.45063783103855 },
      zoom: 12,

      arrayClientesVisitados: [],
      arrayClientesNoVisitados: [],
      arrayClientesSinLocalizacion: [],

      markerVisita: { lat: 0, lng: 0 },
      markerCliente: { lat: 0, lng: 0 },

      headers: [
        {
          text: "Cliente",
          value: "razon_social",
          sortable: false,
        },
        {
          text: "Día",
          value: "dia",
          sortable: false,
        },
        {
          text: "Hora/duración",
          value: "duracion",
          sortable: false,
        },
      ],

      visitaSelected: null, // Esta propiedad almacenara el index de la visita seleccionada cuando se hace click en la row de la tabla
      selected: [], //Esta propiedad almacena la visita que esta seleccionada en la tabla
    };
  },
  methods: {
    visitaAnterior() {
      if (this.visitaSelected === null) {
        this.visitaSelected = 0;
        this.selected = [this.visitas[0]];
      }

      if (this.visitaSelected === 0) {
        this.visitaSelected = this.visitas.length - 1;
        this.selected = [this.visitas[this.visitas.length - 1]];
      } else {
        this.visitaSelected = this.visitaSelected - 1;
        this.selected = [this.visitas[this.visitaSelected]]; //Se quita el -1 ya que se le acaba de restar uno en la linea anterior
      }
    },
    visitaSiguiente() {
      if (this.visitaSelected === null) {
        this.visitaSelected = 0;
        this.selected = [this.visitas[0]];
      } else {
        if (this.visitaSelected === this.visitas.length - 1) {
          this.visitaSelected = 0;
          this.selected = [this.visitas[0]];
        } else {
          this.visitaSelected = this.visitaSelected + 1;
          this.selected = [this.visitas[this.visitaSelected]]; //Se quita el +1 ya que se le acaba de restar uno en la linea anterior
        }
      }
    },
    onRowClick(item) {
      this.visitaSelected = item.index;
      // const selected = [item];
      // this.selected = selected;
      this.selected = [item];
    },
    calcularDuracion(inicio, fin) {
      const fechaInicio = new Date(inicio);
      const fechaFin = new Date(fin);
      const diferenciaEnMilisegundos = fechaFin - fechaInicio;
      const minutos = Math.floor(diferenciaEnMilisegundos / 60000); // 1 minuto = 60000 milisegundos

      // Convertimos a formato local en AM/PM automáticamente
      const formatoInicio = fechaInicio.toLocaleString("es-MX", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const formatoFin = fechaFin.toLocaleString("es-MX", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      const rangoHoras = `De ${formatoInicio} a ${formatoFin}`;
      const formatoDuracion = `Duración: ${minutos} minutos`;

      return rangoHoras + "<br/>" + formatoDuracion;
    },
  },
  watch: {
    visitaSelected(nuevaVisita) {
      // console.log(`Cambio de ${valorAnterior} a ${nuevaVisita}`);
      // console.log(this.visitas[nuevaVisita]);

      this.zoom = 18;
      this.center = {
        lat: this.visitas[nuevaVisita].latitud,
        lng: this.visitas[nuevaVisita].longitud,
      };
      this.markerVisita = {
        lat: this.visitas[nuevaVisita].latitud,
        lng: this.visitas[nuevaVisita].longitud,
      };
      this.markerCliente = {
        lat: this.visitas[nuevaVisita].latitud_cliente,
        lng: this.visitas[nuevaVisita].longitud_cliente,
      };
    },
    selected(valor) {
      // console.log(valor);
      this.visitaSelected = valor[0].index;
    },
    items() {
      this.arrayClientesVisitados = [];
      this.arrayClientesNoVisitados = [];
      this.arrayClientesSinLocalizacion = [];
    },
  },
  computed: {
    visitas() {
      this.items.map((cliente) => {
        cliente.visitas.map((visita) => {
          visita.razon_social = cliente.razon_social;
          visita.latitud_cliente = cliente.latitud;
          visita.longitud_cliente = cliente.longitud;

          const fechaFormato = parseISO(visita.inicio);
          const diaMinusculas = format(fechaFormato, "EEEE", { locale: es });
          // Convertir la primera letra en mayúscula
          visita.dia =
            diaMinusculas.charAt(0).toUpperCase() + diaMinusculas.slice(1);
        });
      });
      const todasLasVisitas = this.items.flatMap((cliente) => cliente.visitas);
      if (todasLasVisitas.length !== 0) {
        todasLasVisitas.sort((a, b) => {
          //comparar fechas
          const fechaA = new Date(a.inicio);
          const fechaB = new Date(b.inicio);

          if (fechaA < fechaB) {
            return -1;
          } else if (fechaA > fechaB) {
            return 1;
          }

          return 0;
        });
      }
      let index = 0;
      todasLasVisitas.map((visita) => (visita.index = index++));
      // Ahora 'todasLasVisitas' contiene todos los objetos de las visitas
      console.log(todasLasVisitas);
      return todasLasVisitas;
    },
    datosClientes() {
      let clientesVisitados = 0;
      let clientesNoVisitados = 0;
      let clientesSinLocalizacion = 0;

      // Recorre el array de clientes
      this.items.forEach((cliente) => {
        // Verifica si el cliente tiene al menos una visita con 'visitado' igual a true
        const visitado = cliente.visitas.some(
          (visita) => visita.visitado === true
        );

        // Verifica si el cliente tiene latitud o longitud igual a null
        const sinLocalizacion =
          cliente.latitud === null || cliente.longitud === null;

        if (sinLocalizacion) {
          clientesSinLocalizacion++;
          this.arrayClientesSinLocalizacion.push(cliente);
        } else {
          // Incrementa los contadores según las condiciones
          if (visitado) {
            clientesVisitados++;
            this.arrayClientesVisitados.push(cliente);
          } else {
            clientesNoVisitados++;
            this.arrayClientesNoVisitados.push(cliente);
          }
        }
      });

      // Crea el objeto final con los resultados

      return {
        clientesVisitados: clientesVisitados, //4
        clientesNoVisitados: clientesNoVisitados, //9
        clientesSinLocalizacion: clientesSinLocalizacion, //3
        total: this.items.length,
      };
    },
  },
  created() {},
  components: { DoughnutComponent },
};
</script>
