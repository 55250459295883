<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              mdi-dots-vertical
            </v-icon>
          </template>
          <span>Acciones</span>
        </v-tooltip>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="selectComponent(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="dialog2 = true">
          <v-list-item-title>Facturas pendientes</v-list-item-title>
        </v-list-item>

        <v-list-item @click="dialog3 = true">
          <v-list-item-title>Ver localizaciones</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="70%" v-model="dialog">
      <v-card v-if="selectedComponent === 'VerGuia'">
        <VerGuia :item="item" @cerrarDialog="cerrarDialog"></VerGuia>
      </v-card>
      <!---->
      <v-card v-if="selectedComponent === 'AgregarLatitudLongitud'">
        <AgregarLatitudLongitud
          :item="item"
          @cerrarDialog="cerrarDialog"
          @clienteModificado="clienteModificado"
        ></AgregarLatitudLongitud>
      </v-card>
      <v-card v-if="selectedComponent === 'ModificarActivoSaux'">
        <ModificarActivoSaux
          :item="item"
          @cerrarDialog="cerrarDialog"
          @clienteModificado="clienteModificado"
        >
        </ModificarActivoSaux>
      </v-card>
      <v-card v-if="selectedComponent === 'ModificarGiro'">
        <ModificarGiro
          :item="item"
          @cerrarDialog="cerrarDialog"
          @clienteModificado="clienteModificado"
        >
        </ModificarGiro>
      </v-card>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialog2"
    >
      <FacturaPendiente :item="item" @cerrarDialog="cerrarDialog">
      </FacturaPendiente>
    </v-dialog>
    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      v-model="dialog3"
    >
      <VerLocalizaciones :item="item" @cerrarDialog="cerrarDialog">
      </VerLocalizaciones>
    </v-dialog>
  </v-container>
</template>

<script>
// import axios from "axios";
import AgregarLatitudLongitud from "./AgregarLatitudLongitud.vue";
import ModificarActivoSaux from "./ModificarActivoSaux.vue";
import VerGuia from "./VerGuia.vue";
import ModificarGiro from "./ModificarGiro.vue";
import FacturaPendiente from "./FacturaPendiente.vue";
import VerLocalizaciones from "./VerLocalizaciones.vue";
export default {
  props: ["item"],
  components: {
    AgregarLatitudLongitud,
    VerGuia,
    ModificarActivoSaux,
    ModificarGiro,
    FacturaPendiente,
    VerLocalizaciones,
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      items: [
        {
          title: "Ver guía",
          component: "VerGuia",
        },
        {
          title: "Modificar activo en saux",
          component: "ModificarActivoSaux",
        },
        {
          title: "Modificar giros",
          component: "ModificarGiro",
        },
      ],
      selectedComponent: null,
    };
  },
  methods: {
    cerrarDialog(dialog) {
      this.dialog = dialog;
      this.dialog2 = dialog;
      this.dialog3 = dialog;
    },
    clienteModificado() {
      this.$emit("clienteModificado");
    },
    selectComponent(item) {
      if (item.component) {
        this.selectedComponent = item.component;
        this.dialog = true;
      }
    },
    // verGuia() {
    //   alert("Desplegar el componente guia");
    // },
    // agregarLatitudLongitud() {
    //   alert("Desplegar el componente agregar latitud");
    // },
  },
};
</script>
./FacturaPendiente.vue
