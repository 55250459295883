var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-3"},[_c('v-card-title',[_vm._v(" Editar Usuarios "),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-btn',{class:[
            {
              'disabled-button': _vm.currentView != 'UsuariosView',
              'enabled-button': _vm.currentView === 'UsuariosView',
            },
            'mr-1',
            'ml-3',
            'mb-3',
          ],attrs:{"color":"primary"},on:{"click":function($event){_vm.currentView = 'UsuariosView'}}},[_vm._v(" Usuarios ")]),_c('v-btn',{class:[
            {
              'disabled-button': _vm.currentView != 'AsignacionesView',
              'enabled-button': _vm.currentView === 'AsignacionesView',
            },
            'mr-1',
            'ml-3',
            'mb-3',
          ],attrs:{"color":"primary"},on:{"click":function($event){_vm.currentView = 'AsignacionesView'}}},[_vm._v(" Asignaciones ")]),_c('v-btn',{class:[
            {
              'disabled-button': _vm.currentView != 'PerfilesView',
              'enabled-button': _vm.currentView === 'PerfilesView',
            },
            'mr-1',
            'ml-3',
            'mb-3',
          ],attrs:{"color":"primary"},on:{"click":function($event){_vm.currentView = 'PerfilesView'}}},[_vm._v(" Perfiles ")])],1)],1),(_vm.currentView === 'AsignacionesView')?_c('AsignacionesView'):_vm._e(),(_vm.currentView === 'PerfilesView')?_c('PerfilesView'):_vm._e(),(_vm.currentView === 'UsuariosView')?_c('v-card',[_c('v-card-title',{staticClass:"text-center teal lighten-3 accent-4 white--text"},[_vm._v(" Usuarios "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"elevation":0,"dark":""}},'div',attrs,false),on),[_c('CrearUsuario')],1)]}}],null,false,770522093)},[_c('span',[_vm._v("Nuevo Usuario")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.itemsNombre,"search":_vm.search,"items-per-page":-1,"footer-props":{
          'items-per-page-text': 'Usuarios por página',
        },"header-props":{
          'sort-by-text': 'Ordenar por',
        }},scopedSlots:_vm._u([{key:`item.fecha_creacion`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.fecha_creacion.slice(0, 10))+" ")]}},{key:`item.activo`,fn:function({ item }){return [_c('UsuarioActivo',{attrs:{"item":item}})]}},{key:`item.acciones`,fn:function({ item }){return [_c('AccionesComponente',{attrs:{"item":item}})]}},{key:`item.permisos`,fn:function({ item }){return [(item.permisos)?_c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Permisos ")]),_c('v-expansion-panel-content',[(
                      Array.isArray(item.permisos.permisos_disponibles) &&
                      item.permisos.permisos_disponibles.length > 1
                    )?[_c('v-list',_vm._l((item.permisos.split(' ')),function(permiso,index){return _c('v-list-item',{key:index},[_vm._v(" "+_vm._s(permiso)+" ")])}),1)]:[_vm._v(" "+_vm._s(item.permisos)+" ")]],2)],1)],1)],1):_vm._e()]}}],null,true)})],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }