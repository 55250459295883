<template>
  <v-container v-if="user" fluid>
    <v-card class="pa-3">
      <v-card-title>
        Editar Usuarios
        <v-spacer />
      </v-card-title>
      <v-row>
        <v-col>
          <v-btn
            :class="[
              {
                'disabled-button': currentView != 'UsuariosView',
                'enabled-button': currentView === 'UsuariosView',
              },
              'mr-1',
              'ml-3',
              'mb-3',
            ]"
            color="primary"
            @click="currentView = 'UsuariosView'"
          >
            Usuarios
          </v-btn>
          <v-btn
            :class="[
              {
                'disabled-button': currentView != 'AsignacionesView',
                'enabled-button': currentView === 'AsignacionesView',
              },
              'mr-1',
              'ml-3',
              'mb-3',
            ]"
            color="primary"
            @click="currentView = 'AsignacionesView'"
          >
            Asignaciones
          </v-btn>
          <v-btn
            :class="[
              {
                'disabled-button': currentView != 'PerfilesView',
                'enabled-button': currentView === 'PerfilesView',
              },
              'mr-1',
              'ml-3',
              'mb-3',
            ]"
            color="primary"
            @click="currentView = 'PerfilesView'"
          >
            Perfiles
          </v-btn>
        </v-col>
      </v-row>
      <AsignacionesView v-if="currentView === 'AsignacionesView'" />
      <PerfilesView v-if="currentView === 'PerfilesView'" />
      <v-card v-if="currentView === 'UsuariosView'">
        <v-card-title class="text-center teal lighten-3 accent-4 white--text">
          Usuarios
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div :elevation="0" dark v-bind="attrs" v-on="on">
                <CrearUsuario />
              </div>
            </template>
            <span>Nuevo Usuario</span>
          </v-tooltip>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="itemsNombre"
          :search="search"
          :items-per-page="-1"
          :footer-props="{
            'items-per-page-text': 'Usuarios por página',
          }"
          :header-props="{
            'sort-by-text': 'Ordenar por',
          }"
        >
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ item.fecha_creacion.slice(0, 10) }}
          </template>
          <template v-slot:[`item.activo`]="{ item }">
            <UsuarioActivo :item="item" />
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <AccionesComponente :item="item"> </AccionesComponente>
          </template>
          <!--  ASIGNACIONES
          <template v-slot:[`item.asignaciones`]="{ item }">
            <div v-if="item.asignaciones">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Asignaciones
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template
                      v-if="
                        Array.isArray(item.asignaciones) &&
                        item.asignaciones.length > 1
                      "
                    >
                      <v-list>
                        <v-list-item
                          v-for="(asignacion, index) in item.asignaciones"
                          :key="index"
                        >
                          {{ asignacion }}
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-else>
                      {{ item.asignaciones }}
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
          -->
          <template v-slot:[`item.permisos`]="{ item }">
            <!-- <div v-if="item.permisos">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header> Permisos </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template
                    v-if="
                      Array.isArray(item.permisos.permisos_disponibles) &&
                      item.permisos.permisos_disponibles.length > 1
                    "
                  >
                    <v-list>
                      <v-list-item
                        v-for="(permiso, index) in item.permisos
                          .permisos_disponibles"
                        :key="index"
                      >
                        {{ permiso }}
                      </v-list-item>
                    </v-list>
                  </template>
                  <template v-else>
                    {{ item.permisos.permisos_disponibles[0] }}
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div> -->

            <div v-if="item.permisos">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Permisos
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <template
                      v-if="
                        Array.isArray(item.permisos.permisos_disponibles) &&
                        item.permisos.permisos_disponibles.length > 1
                      "
                    >
                      <v-list>
                        <v-list-item
                          v-for="(permiso, index) in item.permisos.split(' ')"
                          :key="index"
                        >
                          {{ permiso }}
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-else>
                      {{ item.permisos }}
                    </template>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <!-- <v-list-group v-if="item.permisos">
            <template v-for="(permiso, index) in item.permisos.split(' ')">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ permiso }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group> -->
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import UsuarioActivo from "@/components/UsuarioActivo.vue";
import CrearUsuario from "@/components/CrearUsuario.vue";
import AccionesComponente from "@/components/AccionesComponente.vue";
import AsignacionesView from "./AsignacionesView.vue";
import PerfilesView from "./PerfilesView.vue";
export default {
  components: {
    UsuarioActivo,
    CrearUsuario,
    AccionesComponente,
    PerfilesView,
    AsignacionesView,
  },
  data() {
    return {
      currentView: "UsuariosView",
      items: [],
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Correo",
          value: "correo",
        },
        {
          text: "Activo",
          value: "activo",
        },
        /*
        {
          text: "Asignaciones",
          value: "asignaciones",
        },
        */
        {
          text: "Permisos",
          filterable: false,
          value: "permisos",
        },
        {
          text: "Fecha de creación",
          value: "fecha_creacion",
        },
        {
          text: "GPS",
          value: "gps",
        },
        {
          text: "Adminpaq",
          value: "nombre_adminpaq",
        },
        {
          text: "Permisos extras",
          value: "permisos_extras",
        },
        {
          text: "Sucursal",
          value: "sucursal",
        },
        {
          text: "Acciones",
          value: "acciones",
        },
      ],
    };
  },
  methods: {
    async getUsuarios() {
      try {
        const usuarios = await axios.get("/usuarios/get_usuarios");
        this.items = usuarios.data.usuarios;
      } catch (error) {
        console.error(error);
      }
    },
    getColor(estado) {
      return estado ? "green" : "red";
    },
  },
  created() {
    this.getUsuarios();
  },
  computed: {
    ...mapGetters(["user"]),
    itemsNombre() {
      return this.items.map((usuario) => {
        if (usuario.sucursal === "LaPaz") {
          usuario.sucursal = "La Paz";
        }
        //Se modifica la propiedad de permisos de cada usuario para que no interfiera con las busquedas en la tabla
        // if (usuario.permisos) {
        //   usuario.permisos = {
        //     permisos_disponibles: usuario.permisos.split(" "),
        //   };
        // }

        return usuario;
      });
    },
  },
};
</script>
